<script>
import Base from "@backend/Base.vue";
import Gen from "@helper/Gen";
import BOGen from "@helper/BOGen";
import GlobalVue from "@helper/Global.vue";
import "@backend/css/pages/login-register-lock.css";

export default {
  extends: GlobalVue,
  data() {
    return {
      input: {},
      isEmail: false,
      alertPass: false,
      disabled: false,
    };
  },
  computed: {
    yearcopy() {
      return Gen.yearCopy(this.web.yearcopy);
    },
    mrValidation() {
      let x = [];
      x["password"] = "required|minlength:5|maxlength:50";
      x["confpassword"] = "required|minlength:5|maxlength:50|equalTo:#password";
      return x;
    },
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.refreshVariable();
    if (this.$route.query.code) {
      this.isEmail = true;
      this.isExpired();
    } else {
      this.isEmail = false;
    }
  },
  methods: {
    isExpired() {
      BOGen.apirest(
        "/recovery-code",
        { code: this.$route.query.code },
        (err, resp) => {
          if (resp.redirect) {
            this.$router.push({
              name: "BoLogin",
            });
          }
        }
      );
    },
    submitEmail(e) {
      if (e.btnLoading()) return;
      BOGen.apirest(
        "/sent-email",
        this.input,
        (err, resp) => {
          e.btnUnloading();
          if (err) return console.log(err);
          if (resp.error) {
            return Gen.info(resp.message, "danger");
          }
          Gen.info(resp.message, "success", 2000).then(() => {
              this.$router.push({
                name: "BoLogin",
              });
          });
        },
        "POST"
      );
    },
    submitForgot(e) {
      if (e.btnLoading()) return;
      this.input.code = this.$route.query.code;
      BOGen.apirest(
        "/change-password",
        this.input,
        (err, resp) => {
          e.btnUnloading();
          if (err) return console.log(err);
          if (resp.error) {
            return Gen.info(resp.message, "danger", 4000);
          }
          swal("Sukses!", resp.message, "success");
          setTimeout(() => {
            this.$router.push({
              name: "BoLogin",
            });
          }, 2000);
        },
        "POST"
      );
    },
    toogleClick(id, cl) {
      $("." + cl).toggleClass("fa-eye fa-eye-slash");
      var input = $("#" + id);
      if (input.attr("type") === "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    },
  },
  watch: {},
};
</script>

<template>
  <section id="wrapper">
    <div
      class="login-register"
      :style="
        'background-image: url(\'' +
          assets('bo_images', 'login-register2.jpg') +
          '\');'
      "
    >
      <div class="login-box">
        <div class="card mb-0">
          <div class="card-body">
            <router-link
              :to="{ name: 'BoLogin' }"
              v-if="!isEmail"
              class="d-inline-block mb-4 font-size-xs"
              ><i class="ti-arrow-left mr-2 align-middle"></i>Kembali</router-link
            >
            <VForm
              @resp="submitEmail"
              class="form-horizontal form-material"
              id="loginform"
              v-if="!isEmail"
            >
              <div class="info"></div>
              <div class="text-center mb-3">
                <h5 class="card-title mb-2">Recovery Password</h5>
                <h6 class="card-subtitle">
                  Masukkan E-Mail terdaftar anda. Kami akan mengirimkan instruksi ke E-Mail anda.
                </h6>
              </div>
              <div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    v-model="input.email"
                    id="email"
                    required
                    class="form-control"
                    title="Input Email"
                  />
                </div>
              </div>
              <div class="form-group text-center mb-0">
                <div class="col-xs-12 text-center">
                  <button
                    class="btn btn-block btn-info btn-rounded btn-loading"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </VForm>
            <VForm
              @resp="submitForgot"
              class="form-horizontal form-material"
              id="loginform"
              v-else
            >
              <div>
                <div class="info"></div>
                <div class="text-center mb-5">
                  <h5 class="card-title mb-2">Recovery Password</h5>
                  <h6></h6>
                </div>
                <div class="form-group">
                  <label for="password">New Password</label>
                  <div class="position-relative">
                    <input
                      type="password"
                      name="password"
                      v-bind="validation('password')"
                      v-model="input.password"
                      id="password"
                      required
                      class="form-control"
                    />
                    <div
                      @click="toogleClick('password', 'icon_pass')"
                      class="input-group-append seePass"
                    >
                      <span class="input-group-text bg-transparent border-0"
                        ><i class="icon_pass fa fa-eye-slash"></i
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="confpassword">Confirm New Password</label>
                  <div class="position-relative">
                    <input
                      type="password"
                      name="confpassword"
                      v-bind="validation('confpassword')"
                      v-model="input.confpassword"
                      id="confpassword"
                      required
                      class="form-control"
                    />
                    <div
                      @click="toogleClick('confpassword', 'icon_confpass')"
                      class="input-group-append seePass"
                    >
                      <span class="input-group-text bg-transparent border-0"
                        ><i class="icon_confpass fa fa-eye-slash"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-block btn-info btn-rounded btn-loading"
                type="submit"
                :disabled="disabled"
              >
                Submit
              </button>
            </VForm>
          </div>
          <div class="col-sm-12 text-center copyright">
            <p>
              {{ yearcopy }} &copy; SDI-Al Hidayah. All Rights Reserved. <br />
              Powered by
              <a target="_blank" href="http://lingkar9.com/">Lingkar9</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .seePass {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
